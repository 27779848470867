package net.niiranen.site.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.AlignItems
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Height
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.alignItems
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexDirection
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.grid
import com.varabyte.kobweb.compose.ui.modifiers.gridArea
import com.varabyte.kobweb.compose.ui.modifiers.gridTemplateAreas
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.setVariable
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toAttrs
import com.varabyte.kobweb.silk.style.until
import com.varabyte.kobweb.silk.style.vars.color.BackgroundColorVar
import com.varabyte.kobweb.silk.style.vars.color.ColorVar
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import net.niiranen.site.components.layouts.PageLayout
import net.niiranen.site.components.text.Body
import net.niiranen.site.components.text.BodySmall
import net.niiranen.site.components.text.Display
import net.niiranen.site.components.text.DisplaySmall
import net.niiranen.site.components.text.Headline
import net.niiranen.site.components.text.HeadlineSmall
import net.niiranen.site.components.text.Label
import net.niiranen.site.components.text.LabelLarge
import net.niiranen.site.components.text.Strong
import net.niiranen.site.components.text.Title
import net.niiranen.site.components.widgets.Card
import net.niiranen.site.components.widgets.LinkText
import net.niiranen.site.components.widgets.MailIcon
import net.niiranen.site.toSecondaryColorFamily
import net.niiranen.site.toSitePalette
import net.niiranen.site.toTertiaryColorFamily
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.fr
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Li
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Ul

val CvGridStyle = CssStyle {
    base {
        Modifier.height(Height.Inherit)
    }
    until(Breakpoint.LG) {
        Modifier
            .gap(2.cssRem)
            .gridTemplateAreas(
                "main",
                "side",
            )
            .grid {
                columns { size(100.percent) }
                auto { rows { size(maxContent) } }
            }
    }

    Breakpoint.LG {
        Modifier
            .gap(5.cssRem)
            .gridTemplateAreas(
                "main side",
            )
            .grid {
                rows {
                    size(100.percent)
                }
                columns {
                    size(3.fr)
                    size(1.fr)
                }
            }
    }
}

@Page
@Composable
fun CV() = PageLayout("Nina Niiranen - CV") {
    Div(CvGridStyle.toAttrs()) {
        Column(
            modifier = Modifier.gridArea("main"),
        ) {
            Display("Nina Niiranen")
            LabelLarge("Lead Android Engineer")
            P()
            HeadlineSmall("Profile")
            Body(
                "A seasoned Android developer with a proven track record of delivering high quality mobile" +
                        " applications. I bring a wealth of experience and expertise in various aspects of mobile" +
                        " development, including architecture, code quality, and technical leadership." +
                        " I am passionate about building robust, scalable, and maintainable software solutions while" +
                        " building a culture of knowledge sharing and learning within a technical organisation."
            )
            P()
            DisplaySmall("Employment History")
            Bontouch()
            Doktor()
            Dreams()
            MagPlus()
            P()
            Education()
        }
        val altColors = ColorMode.current.toSitePalette().toSecondaryColorFamily()
        Card(
            modifier = Modifier.setVariable(ColorVar, altColors.onColorContainer)
                .setVariable(BackgroundColorVar, altColors.colorContainer)
                .backgroundColor(altColors.colorContainer)
                .gridArea("side")
                .flexDirection(FlexDirection.Column)
                .alignItems(AlignItems.Start),
        ) {
            IdealJob()
            Column(modifier = Modifier.padding(1.cssRem)) {
                Links()
                Technologies()
            }
        }
    }
}

@Composable
private fun Bontouch() {
    Column {
        Span {
            Headline("Lead Android Engineer")
            HeadlineSmall(" — Bontouch, London")
        }
        Label("February 2023 — October 2024")
        P {
            Body("As a Lead Android Engineer at Bontouch, working for the client SEB, developing banking applications used by millions of people, I focused on technical quality and stability to improve developer productivity when developing new features.")
            Body("Within Bontouch's Android competence area I fostered a more open and collaborative approach by being open and public with my own work and helping others finding solutions.")
        }
        Title("Key Accomplishments:")
        Ul {
            Li {
                Body {
                    Strong("Architecture: ")
                }
                Body("I established a solid architecture and guidelines for modernising a mission-critical, ageing code base through collaborative efforts within the Android community.")
            }
            Li {
                Body {
                    Strong("Leadership: ")
                }
                Body("Led the migration strategy to modularise the existing project, effectively untangling deep-rooted technical debt.")
            }
            Li {
                Body {
                    Strong("Collaboration: ")
                }

                Body("I fostered a collaborative and knowledge-sharing culture within the larger Android team at Bontouch.")
            }
        }
    }
}

@Composable
private fun Doktor() {
    Column {
        Span {
            Headline("Senior Android Engineer")
            HeadlineSmall(" — Doktor.se, Stockholm")
        }
        Label("December 2021 — February 2023")
        P {
            Body("Doktor.se provides digital and physical healthcare services, with B2B SDKs, white label applications, and B2C market specific applications.")
            Body("During my time at Doktor.se I worked with both the white label applications and led the Android side of the Swedish market application.")
        }
        Title("Key Accomplishments:")
        Ul {
            Li {
                Body {
                    Strong("Modernised Design Systems: ")
                }
                Body("I collaborated with the SDK team to update the application's architecture and establish a design system to ensure consistency across the B2C and B2B product integrations.")
            }
            Li {
                Body {
                    Strong("Future Proofing Architecture: ")
                }
                Body("Collaborated with the Android team to update the application's architecture, transitioning from a MVP and MVVM approach to a MVI architecture. This shift improved the application's maintainability, testability, and overall code quality.")
            }
            Li {
                Body {
                    Strong("Technical Quality: ")
                }

                Body("I lifted technical quality by providing good PR reviews to help more junior developers grow.")
            }
        }
    }
}

@Composable
private fun Dreams() {
    Column {
        Span {
            Headline("Senior Android Engineer")
            HeadlineSmall(" — Dreams, Stockholm")
        }
        Label("August 2016 — December 2021")
        P {
            Body("Dreams is a fin-tech company in multiple markets that focuses on improving personal financial wellbeing by nudging users using behavioural science.")
        }
        Title("Key Accomplishments:")
        Ul {
            Li {
                Body {
                    Strong("Product Leadership: ")
                }
                Body("Led the development of a debt reconciliation product in Norway by defining technical implications of legal requirements and communicating development challenges to product owners.")
            }
            Li {
                Body {
                    Strong("Team Collaboration: ")
                }
                Body("Collaborated with cross-functional teams, gathering technical requirements, contributing to architecture design, and developing the Android application. I also led the work building the B2B SDK.")
            }
        }
    }
}

@Composable
private fun MagPlus() {
    Column {
        Span {
            Headline("Android Engineer")
            HeadlineSmall(" — Mag+, Stockholm")
        }
        Label("April 2013 — August 2016")
        P {
            Body("Mag+ was a digital magazine publishing platform providing white label applications for business clients with many options for brand specific customisation.")
        }
    }
}

@Composable
private fun Education() {
    DisplaySmall("Education")
    Span {
        Headline("Game design & Programming")
        HeadlineSmall(" — Gotland University, Visby")
    }
    Label("2008 — 2012")
    Body(
        "Game design and programming studies, focusing on practical courses. " +
                "Programming mainly in C++, using OpenGL/DirectX as well as custom renderers and game engines."
    )
}

@Composable
private fun Technologies() {
    Title("Technologies")
    Column {
        BodySmall("MVVM, MVI, MVP.")
        BodySmall(
            "Gradle, CI/CD, TeamCity, Fastlane, GitHub${Typography.nbsp}Actions, " +
                    "Circle${Typography.nbsp}CI, Unit${Typography.nbsp}&${Typography.nbsp}E2E${Typography.nbsp}Testing."
        )
        BodySmall("KMP, Jetpack Compose, React-Native.")
    }
}

@Composable
private fun Links() {
    Title("Links")
    Column {
        BodySmall { LinkText("https://www.linkedin.com/in/ninaniiranen/", "LinkedIn") }
        BodySmall {
            Link(path = "mailto:hello@niiranen.net") {
                Text("hello@niiranen.net")
                MailIcon(Modifier.fontSize(12.pt).fontWeight(FontWeight.Light))
            }
        }
    }
}


@Composable
private fun IdealJob() {
    val altColors = ColorMode.current.toSitePalette().toTertiaryColorFamily()
    Card(
        modifier = Modifier.setVariable(ColorVar, altColors.onColorContainer)
            .setVariable(BackgroundColorVar, altColors.colorContainer)
            .backgroundColor(altColors.colorContainer)
            .padding(1.cssRem)
            .fillMaxWidth()
            .flexDirection(FlexDirection.Column)
            .alignItems(AlignItems.Start),
    ) {
        Title("My Ideal Job")
        Body("In the ideal role I focus on improving technical quality and knowledge across an organisation.")
    }
}
