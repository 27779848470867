import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.BasePath
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.navigation.remove
import com.varabyte.kobweb.silk.defer.DeferringHost
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    AppGlobals.initialize(mapOf("title" to "Niiranen"))
    BasePath.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { net.niiranen.site.pages.HomePage() }
        ctx.router.register("/cv") { net.niiranen.site.pages.CV() }
        ctx.router.register("/links") { net.niiranen.site.pages.LinksPage() }
        ctx.router.register("/projects") { net.niiranen.site.pages.Projects() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerStyle("container", net.niiranen.site.ContainerStyle)
        ctx.theme.registerStyle("card", net.niiranen.site.CardStyle)
        ctx.theme.registerStyle("body-large", net.niiranen.site.BodyLargeStyle)
        ctx.theme.registerStyle("body", net.niiranen.site.BodyStyle)
        ctx.theme.registerStyle("body-small", net.niiranen.site.BodySmallStyle)
        ctx.theme.registerStyle("display-large", net.niiranen.site.DisplayLargeStyle)
        ctx.theme.registerStyle("display", net.niiranen.site.DisplayStyle)
        ctx.theme.registerStyle("display-small", net.niiranen.site.DisplaySmallStyle)
        ctx.theme.registerStyle("headline-large", net.niiranen.site.HeadlineLargeStyle)
        ctx.theme.registerStyle("headline", net.niiranen.site.HeadlineStyle)
        ctx.theme.registerStyle("headline-small", net.niiranen.site.HeadlineSmallStyle)
        ctx.theme.registerStyle("title-large", net.niiranen.site.TitleLargeStyle)
        ctx.theme.registerStyle("title", net.niiranen.site.TitleStyle)
        ctx.theme.registerStyle("title-small", net.niiranen.site.TitleSmallStyle)
        ctx.theme.registerStyle("label-large", net.niiranen.site.LabelLargeStyle)
        ctx.theme.registerStyle("label", net.niiranen.site.LabelStyle)
        ctx.theme.registerStyle("label-small", net.niiranen.site.LabelSmallStyle)
        ctx.theme.registerStyle("code", net.niiranen.site.CodeStyle)
        ctx.theme.registerStyle("emoji", net.niiranen.site.EmojiStyle)
        ctx.theme.registerStyle("quote", net.niiranen.site.QuoteStyle)
        ctx.theme.registerStyle("strong", net.niiranen.site.StrongStyle)
        ctx.theme.registerStyle("page-content",
                net.niiranen.site.components.layouts.PageContentStyle)
        ctx.theme.registerStyle("footer", net.niiranen.site.components.sections.FooterStyle)
        ctx.theme.registerStyle("nav-header", net.niiranen.site.components.sections.NavHeaderStyle)
        ctx.theme.registerStyle("cv-grid", net.niiranen.site.pages.CvGridStyle)
        ctx.theme.registerStyle("hero-container", net.niiranen.site.pages.HeroContainerStyle)
        ctx.theme.registerStyle("hero-card", net.niiranen.site.pages.HeroCardStyle)
        ctx.theme.registerVariant("-circle", net.niiranen.site.CircleButtonVariant
        )
        ctx.theme.registerVariant("-button", net.niiranen.site.ButtonVariant
        )
        ctx.theme.registerVariant("-uncolored", net.niiranen.site.UncoloredButtonVariant
        )
        ctx.theme.registerKeyframes("side-menu-slide-in",
                net.niiranen.site.components.sections.SideMenuSlideInAnim)
        net.niiranen.site.initColorMode(ctx)
        net.niiranen.site.initSiteStyles(ctx)
        net.niiranen.site.initTheme(ctx)
    }

    router.tryRoutingTo(BasePath.remove(window.location.href.removePrefix(window.origin)),
            UpdateHistoryMode.REPLACE)

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    renderComposable(rootElementId = "root") {
        net.niiranen.site.AppEntry {
            router.renderActivePage { DeferringHost { it() } }
        }
    }
}
