package net.niiranen.site.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.AlignItems
import com.varabyte.kobweb.compose.css.AlignSelf
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.alignItems
import com.varabyte.kobweb.compose.ui.modifiers.alignSelf
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.display
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.grid
import com.varabyte.kobweb.compose.ui.modifiers.gridArea
import com.varabyte.kobweb.compose.ui.modifiers.gridTemplateAreas
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toAttrs
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import net.niiranen.site.components.layouts.PageLayout
import net.niiranen.site.components.text.Body
import net.niiranen.site.components.text.Display
import net.niiranen.site.components.text.HeadlineSmall
import net.niiranen.site.components.text.Quote
import net.niiranen.site.components.text.Text
import net.niiranen.site.components.widgets.Card
import net.niiranen.site.components.widgets.LinkButton
import net.niiranen.site.components.widgets.NinasAvatar
import net.niiranen.site.components.widgets.QuoteIcon
import net.niiranen.site.toSitePalette
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.dom.Br
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.P

val HeroContainerStyle = CssStyle {
    base {
        Modifier
            .display(DisplayStyle.Grid)
            .gridTemplateAreas(
                "main",
                "card",
            ).grid {
                auto {
                    rows {
                        size(25.percent)
                        size(75.percent)
                    }
                }
            }
    }

    Breakpoint.MD {
        Modifier
            .alignSelf(AlignSelf.Center)
            .alignItems(AlignItems.Center)
            .gridTemplateAreas(
                "card . main",
            )
            .grid {
                auto {
                    columns {
                        size(35.percent)
                        size(5.percent)
                        size(60.percent)
                    }
                }
            }
    }
}

@Page
@Composable
fun HomePage() = PageLayout("Home") {
    Div(HeroContainerStyle.toAttrs()) {
        Column(
            modifier = Modifier.gridArea("card"),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            HeroCard()
            Br()
            Body(text = "Formerly at")
            SimpleGrid(
                numColumns = numColumns(2),
            ) {
                Box(modifier = Modifier.padding(all = 4.pt)) {
                    LinkButton(
                        href = "https://bontouch.com",
                        text = "Bontouch",
                        modifier = Modifier.fillMaxWidth(),
                    )
                }
                Box(modifier = Modifier.padding(all = 4.pt)) {
                    LinkButton(
                        href = "https://doktor.se",
                        text = "Doktor.se",
                        modifier = Modifier.fillMaxWidth(),
                    )
                }
                Box(modifier = Modifier.padding(all = 4.pt)) {
                    LinkButton(
                        href = "https://getdreams.com",
                        text = "Dreams",
                        modifier = Modifier.fillMaxWidth(),
                    )
                }
                Box(modifier = Modifier.padding(all = 4.pt)) {
                    LinkButton(
                        href = "https://magplus.com",
                        text = "Mag+",
                        modifier = Modifier.fillMaxWidth(),
                    )
                }
            }
        }
        Column(
            modifier = Modifier.padding(topBottom = 2.em).gridArea("main"),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            Display(attrs = Modifier.textAlign(TextAlign.Center).toAttrs()) {
                Text("Lead Android Engineer")
                HorizontalDivider(Modifier.color(ColorMode.current.toSitePalette().secondary))
            }
            HeadlineSmall(attrs = Modifier.textAlign(TextAlign.Center).toAttrs()) {
                Text("Hi there! I'm Nina, a software engineer by trade and generally a massive nerd.")
            }
            Body(attrs = Modifier.textAlign(TextAlign.Center).toAttrs()) {
                P {
                    Text("I've been writing Android 🤖 applications professionally since 2013. 👵")
                }
                P {
                    Text("With a desire to always improve user and developer experience I've never hesitated to ")
                    Text("deep-dive into complex issues, be it CI/CD improvements, fixing UX jank, or improving ")
                    Text("code quality.")
                    Br()
                    Text("Whenever I'm left to my own devices I will find high-impact work and take the initiative")
                    Text("to drive it forward. Be it fixing Gradle performance or adding a design system.")
                    Br()
                    Text("In my last couple roles I've spent a lot time introducing MVVM, MVI and Compose into ")
                    Text("projects.")
                }
                Text("Currently based in London 🇬🇧 but called Stockholm 🇸🇪 home until 2023.")
            }
        }
    }
}

val HeroCardStyle = CssStyle {
    base {
        Modifier.fillMaxWidth()
    }
    Breakpoint.MD {
        Modifier.fillMaxWidth(75.percent)
    }
}

@Composable
private fun HeroCard(modifier: Modifier = Modifier) {
    Card(
        modifier = HeroCardStyle.toModifier().then(modifier)
    ) {
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            NinasAvatar(modifier = Modifier.fillMaxWidth())
            Body {
                Quote {
                    QuoteIcon(
                        modifier = Modifier.margin(right = 8.pt)
                            .color(ColorMode.current.toSitePalette().onSecondaryContainer)
                    )
                    Text(
                        text = "If you could bottle up the essence of a Swedish coffee break" + " combined with a fervent Twitter hot take, you'd have Nina.",
                    )
                    Br()
                    Body(
                        text = "— Some AI",
                        modifier = Modifier.margin(leftRight = 8.pt)
                            .color(ColorMode.current.toSitePalette().onSecondaryContainer),
                    )
                }
            }
        }
    }
}
