package net.niiranen.site.components.text

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.silk.style.toAttrs
import net.niiranen.site.DisplayLargeStyle
import net.niiranen.site.DisplaySmallStyle
import net.niiranen.site.DisplayStyle
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Span
import org.w3c.dom.HTMLElement

@Composable
fun Display(text: String, modifier: Modifier = Modifier) {
    Text(text, modifier, DisplayStyle)
}

@Composable
fun DisplayLarge(text: String, modifier: Modifier = Modifier) {
    Text(text, modifier, DisplayLargeStyle)
}

@Composable
fun DisplaySmall(text: String, modifier: Modifier = Modifier) {
    Text(text, modifier, DisplaySmallStyle)
}

@Composable
fun Display(
    attrs: AttrBuilderContext<HTMLElement>? = null,
    content: ContentBuilder<HTMLElement>? = null,
) = Span(attrs = DisplayStyle.toAttrs(attrs), content = content)

@Composable
fun DisplayLarge(
    attrs: AttrBuilderContext<HTMLElement>? = null,
    content: ContentBuilder<HTMLElement>? = null,
) = Span(attrs = DisplayLargeStyle.toAttrs(attrs), content = content)

@Composable
fun DisplaySmall(
    attrs: AttrBuilderContext<HTMLElement>? = null,
    content: ContentBuilder<HTMLElement>? = null,
) = Span(attrs = DisplaySmallStyle.toAttrs(attrs), content = content)
