package net.niiranen.site.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.functions.blur
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.BoxScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backdropFilter
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import net.niiranen.site.components.text.LabelSmall
import net.niiranen.site.toPrimaryColorFamily
import net.niiranen.site.toSitePalette
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text

@Composable
fun Avatar(
    src: String,
    description: String,
    modifier: Modifier = Modifier,
    overlay: @Composable BoxScope.() -> Unit = {},
) {
    Box(
        modifier = modifier,
    ) {
        Image(
            src = src,
            description = description,
            modifier = Modifier.size(100.percent),
        )
        overlay()
    }
}

@Composable
fun NinasAvatar(modifier: Modifier = Modifier) {
    val colors = ColorMode.current.toSitePalette().toPrimaryColorFamily()
    Avatar(
        src = "nina.gif",
        description = "Nina's profile picture (xmas edition)",
        modifier = modifier,
    ) {
        Box(
            modifier = Modifier
                .backdropFilter(blur(2.px))
                .backgroundColor(colors.colorContainer.toHsl().copy(alpha = 0.75f))
                .align(Alignment.BottomStart)
                .fillMaxWidth(),
        ) {
            LabelSmall(attrs = Modifier.align(Alignment.Center).toAttrs()) {
                Text("Image by ")
                LinkText(
                    href = "https://makorie.com/",
                    text = "Makorie",
                    iconModifier = Modifier.fontSize(10.pt)
                )
            }
        }
    }
}