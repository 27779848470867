package net.niiranen.site.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.silk.components.navigation.Link
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.dom.Text

@Composable
fun LinkText(
    href: String, text: String,
    modifier: Modifier = Modifier,
    iconModifier: Modifier = Modifier.fontSize(12.pt).fontWeight(FontWeight.Light),
) {
    Link(path = href, modifier = modifier) {
        Text(text)
        LinkIcon(modifier = iconModifier)
    }
}

@Composable
fun ExternalLinkText(
    href: String, text: String,
    modifier: Modifier = Modifier,
    iconModifier: Modifier = Modifier.fontSize(12.pt).fontWeight(FontWeight.Light),
) {
    Link(path = href, modifier = modifier) {
        Text(text)
        OpenInNewIcon(modifier = iconModifier)
    }
}

