package net.niiranen.site.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.alignItems
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import net.niiranen.site.components.layouts.PageLayout
import net.niiranen.site.components.text.Body
import net.niiranen.site.components.text.Display
import net.niiranen.site.components.text.Headline
import net.niiranen.site.components.widgets.Card
import net.niiranen.site.components.widgets.LinkButton
import net.niiranen.site.components.widgets.LinkText
import net.niiranen.site.components.widgets.OpenInNewIcon
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.dom.Br
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Page
@Composable
fun Projects() = PageLayout(title = "Projects") {
    P {
        Display("Projects")
    }
    SimpleGrid(
        numColumns = numColumns(1, md = 2, lg = 4),
        modifier = Modifier.gap(1.cssRem),
    ) {
        ProjectCard(title = "Roach", href = "https://gitlab.com/niiranen/roach") {
            Text("A project that started as a test bed for architecture and MVI experiments.")
            P {
                Text("Long term plan is to make a audio focused media app that can download media from a ")
                LinkText(href = "https://jellyfin.org", text = "Jellyfin")
                Text(" server.")
            }
        }
        ProjectCard(title = "Spanner", href = "https://gitlab.com/niiranen/spanner") {
            Text("A small library that can convert structured text formats into ")
            LinkText(
                href = "https://developer.android.com/reference/android/text/Spanned",
                text = "Spanned"
            )
            Text(".")
        }
        ProjectCard(title = "Slack File Deleter", href = "https://gitlab.com/niiranen/slack-file-deleter") {
            Text("A cli program to delete files from a slack workspace.")
            Br()
            Text("Made mostly to try out clojure and functional programming.")
        }
        ProjectCard(title = "This site", href = "https://gitlab.com/niiranen/niiranen.net") {
            Text("The source code for this site.")
        }
        ProjectCard(
            title = "Fields of Glory",
            href = "https://game.speldesign.uu.se/projects/3d-games/fields-of-glory-2011/",
        ) {
            Text(
                "Fields of glory is a fast paced multiplayer action game that lets you assume the role of a " +
                        "champion in the latest blood sport sensation. Each match in the arena offers a deep and " +
                        "brutal melee combat experience based around dismembering your opponents. Outside of the " +
                        "arena you are able to fully customize your champion’s statistics and abilities with the use " +
                        "of advanced prosthetics."
            )
        }
        ProjectCard(
            title = "Chubby Chase Race",
            href = "https://game.speldesign.uu.se/projects/arcade-games/chubby-chase-race/",
        ) {
            Text(
                "Chubby Chase Race is a four player racing game with an exciting physical interface; " +
                        "all players steer and control their velocity directly with a large trackball."
            )
        }
    }
}

@Composable
private fun ProjectCard(
    title: String,
    href: String,
    modifier: Modifier = Modifier,
    description: @Composable ColumnScope.() -> Unit,
) {
    Card(
        modifier = Modifier
            .padding(1.cssRem)
            .alignItems(AlignItems.Start)
            .then(modifier),
    ) {
        Column(
            verticalArrangement = Arrangement.spacedBy(1.cssRem),
            modifier = Modifier.fillMaxWidth(),
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.Top,
                horizontalArrangement = Arrangement.SpaceBetween,
            ) {
                Headline(
                    text = title,
                    modifier = Modifier.align(Alignment.CenterVertically),
                )
                LinkButton(
                    href = href,
                ) {
                    OpenInNewIcon(
                        modifier = Modifier.fontWeight(FontWeight.SemiBold),
                    )
                }
            }
            Body {
                description()
            }
        }
    }
}
