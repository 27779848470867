package net.niiranen.site.components.text

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.silk.style.toAttrs
import net.niiranen.site.LabelLargeStyle
import net.niiranen.site.LabelSmallStyle
import net.niiranen.site.LabelStyle
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Span
import org.w3c.dom.HTMLElement

@Composable
fun Label(text: String, modifier: Modifier = Modifier) {
    Text(text, modifier, LabelStyle)
}

@Composable
fun LabelLarge(text: String, modifier: Modifier = Modifier) {
    Text(text, modifier, LabelLargeStyle)
}

@Composable
fun LabelSmall(text: String, modifier: Modifier = Modifier) {
    Text(text, modifier, LabelSmallStyle)
}

@Composable
fun Label(
    attrs: AttrBuilderContext<HTMLElement>? = null,
    content: ContentBuilder<HTMLElement>? = null,
) = Span(attrs = LabelStyle.toAttrs(attrs), content = content)

@Composable
fun LabelLarge(
    attrs: AttrBuilderContext<HTMLElement>? = null,
    content: ContentBuilder<HTMLElement>? = null,
) = Span(attrs = LabelLargeStyle.toAttrs(attrs), content = content)

@Composable
fun LabelSmall(
    attrs: AttrBuilderContext<HTMLElement>? = null,
    content: ContentBuilder<HTMLElement>? = null,
) = Span(attrs = LabelSmallStyle.toAttrs(attrs), content = content)
