package net.niiranen.site.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
private fun MaterialIcon(name: String, modifier: Modifier = Modifier) {
    Span(attrs = modifier.toAttrs { classes("material-symbols-rounded") }) {
        Text(name)
    }
}

@Composable
fun LinkIcon(modifier: Modifier = Modifier) {
    MaterialIcon(name = "link", modifier = modifier)
}

@Composable
fun OpenInNewIcon(modifier: Modifier = Modifier) {
    MaterialIcon(name = "open_in_new", modifier = modifier)
}

@Composable
fun QuoteIcon(modifier: Modifier = Modifier) {
    MaterialIcon(name = "format_quote", modifier = modifier)
}

@Composable
fun HomeIcon(modifier: Modifier = Modifier) {
    MaterialIcon(name = "home", modifier = modifier)
}

@Composable
fun CodeBlocksIcon(modifier: Modifier = Modifier) {
    MaterialIcon(name = "code_blocks", modifier = modifier)
}
@Composable
fun CodeIcon(modifier: Modifier = Modifier) {
    MaterialIcon(name = "code", modifier = modifier)
}

@Composable
fun ArrowOutwardIcon(modifier: Modifier = Modifier) {
    MaterialIcon(name = "arrow_outward", modifier = modifier)
}

@Composable
fun ContactMailIcon(modifier: Modifier = Modifier) {
    MaterialIcon(name = "contact_mail", modifier = modifier)
}

@Composable
fun MailIcon(modifier: Modifier = Modifier) {
    MaterialIcon(name = "mail", modifier = modifier)
}

@Composable
fun DocsIcon(modifier: Modifier = Modifier) {
    MaterialIcon(name = "docs", modifier = modifier)
}