package net.niiranen.site

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.letterSpacing
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.theme.name
import org.jetbrains.compose.web.css.cssRem

val BodyLargeStyle = CssStyle{
    base {
        RobotoFlex
            .fontWeight(400)
            .letterSpacing(0.03125f.cssRem)
            .fontSize(1f.cssRem)
            .lineHeight(1.5f.cssRem)
    }
    cssRule(" > .${StrongStyle.name}") {
        Modifier.fontWeight(600)
    }
}

val BodyStyle = CssStyle {
    base {
        RobotoFlex
            .fontWeight(400)
            .letterSpacing(0.015625f.cssRem)
            .fontSize(0.875f.cssRem)
            .lineHeight(1.25f.cssRem)
    }
    cssRule(" > .${StrongStyle.name}") {
        Modifier.fontWeight(600)
    }
}

val BodySmallStyle = CssStyle{
    base {
        RobotoFlex
            .fontWeight(400)
            .letterSpacing(0.025f.cssRem)
            .fontSize(0.75f.cssRem)
            .lineHeight(1f.cssRem)
    }
    cssRule(" > .${StrongStyle.name}") {
        Modifier.fontWeight(600)
    }
}

val DisplayLargeStyle = CssStyle{
    base {
        RobotoSlab
            .fontWeight(400)
            .letterSpacing((-0.015625f).cssRem)
            .fontSize(3.5625f.cssRem)
            .lineHeight(4f.cssRem)
    }
    cssRule(" > .${StrongStyle.name}") {
        Modifier.fontWeight(600)
    }
}

val DisplayStyle = CssStyle{
    base {
        RobotoSlab
            .fontWeight(400)
            .letterSpacing(0f.cssRem)
            .fontSize(2.8125f.cssRem)
            .lineHeight(3.25f.cssRem)
    }
    cssRule(" > .${StrongStyle.name}") {
        Modifier.fontWeight(600)
    }
}

val DisplaySmallStyle = CssStyle{
    base {
        RobotoSlab
            .fontWeight(400)
            .letterSpacing(0f.cssRem)
            .fontSize(2.25f.cssRem)
            .lineHeight(2.75f.cssRem)
    }
    cssRule(" > .${StrongStyle.name}") {
        Modifier.fontWeight(600)
    }
}

val HeadlineLargeStyle = CssStyle{
    base {
        NotoSans
            .fontWeight(400)
            .letterSpacing(0f.cssRem)
            .fontSize(2f.cssRem)
            .lineHeight(2.5f.cssRem)
    }
    cssRule(" > .${StrongStyle.name}") {
        Modifier.fontWeight(600)
    }
}

val HeadlineStyle = CssStyle{
    base {
        NotoSans
            .fontWeight(400)
            .letterSpacing(0f.cssRem)
            .fontSize(1.75f.cssRem)
            .lineHeight(2.25f.cssRem)
    }
    cssRule(" > .${StrongStyle.name}") {
        Modifier.fontWeight(600)
    }
}

val HeadlineSmallStyle = CssStyle{
    base {
        NotoSans
            .fontWeight(400)
            .letterSpacing(0f.cssRem)
            .fontSize(1.5f.cssRem)
            .lineHeight(2f.cssRem)
    }
    cssRule(" > .${StrongStyle.name}") {
        Modifier.fontWeight(600)
    }
}

val TitleLargeStyle = CssStyle {
    base {
        NotoSans
            .fontWeight(400)
            .letterSpacing(0f.cssRem)
            .fontSize(1.375f.cssRem)
            .lineHeight(1.75f.cssRem)
    }
    cssRule(" > .${StrongStyle.name}") {
        Modifier.fontWeight(600)
    }
}

val TitleStyle = CssStyle{
    base {
        NotoSans
            .fontWeight(500)
            .letterSpacing(0.009375f.cssRem)
            .fontSize(1f.cssRem)
            .lineHeight(2f.cssRem)
    }
    cssRule(" > .${StrongStyle.name}") {
        Modifier.fontWeight(700)
    }
}

val TitleSmallStyle = CssStyle{
    base {
        NotoSans
            .fontWeight(500)
            .letterSpacing(0.00625f.cssRem)
            .fontSize(0.875f.cssRem)
            .lineHeight(1.25f.cssRem)
    }
    cssRule(" > .${StrongStyle.name}") {
        Modifier.fontWeight(700)
    }
}

val LabelLargeStyle = CssStyle {
    base {
        RobotoFlex
            .fontWeight(500)
            .letterSpacing(0.00625f.cssRem)
            .fontSize(0.875f.cssRem)
            .lineHeight(1.25f.cssRem)
    }
    cssRule(" > .${StrongStyle.name}") {
        Modifier.fontWeight(700)
    }
}

val LabelStyle = CssStyle {
    base {
        RobotoFlex
            .fontWeight(500)
            .letterSpacing(0.03125f.cssRem)
            .fontSize(0.75f.cssRem)
            .lineHeight(1f.cssRem)
    }
    cssRule(" > .${StrongStyle.name}") {
        Modifier.fontWeight(700)
    }
}

val LabelSmallStyle = CssStyle {
    base {
        RobotoFlex
            .fontWeight(500)
            .letterSpacing(0.03125f.cssRem)
            .fontSize(0.6875f.cssRem)
            .lineHeight(1.cssRem)
    }
    cssRule(" > .${StrongStyle.name}") {
        Modifier.fontWeight(700)
    }
}

val CodeStyle = CssStyle {
    base {
        FiraCode
    }
}

val EmojiStyle = CssStyle {
    base {
        Symbols
    }
}

val QuoteStyle = CssStyle {
    base {
        Modifier.color(colorMode.toSitePalette().onSurfaceVariant)
    }
}

val StrongStyle = CssStyle.base {
    Modifier
}
