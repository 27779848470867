package net.niiranen.site.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.BoxScope
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.style.toModifier
import net.niiranen.site.CardStyle

@Composable
fun Card(
    modifier: Modifier = Modifier,
    content: @Composable BoxScope.() -> Unit,
) {
    Surface(
        modifier = CardStyle.toModifier().then(modifier),
        content = content,
    )
}