package net.niiranen.site.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Width
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.maxSize
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import net.niiranen.site.components.layouts.PageLayout
import net.niiranen.site.components.widgets.ContactMailIcon
import net.niiranen.site.components.widgets.LinkButton
import net.niiranen.site.toSitePalette
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.pt

@Page
@Composable
fun LinksPage() = PageLayout("Links") {
    Box(
        modifier = Modifier.fillMaxWidth().padding(top = 2.cssRem),
        contentAlignment = Alignment.Center,
    ) {
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.spacedBy(2.cssRem),
            modifier = Modifier.width(Width.MaxContent),
        ) {
            LinkButton(
                modifier = Modifier.fillMaxWidth(),
                href = "https://github.com/ninaniiranen",
                text = "GitHub",
                leadingIcon = {
                    Image(
                        src = "/github-logo.png",
                        description = "GitHub Logo",
                        modifier = Modifier.maxSize(24.pt),
                    )
                }
            )
            LinkButton(
                modifier = Modifier.fillMaxWidth(),
                href = "https://gitlab.com/niiranen",
                text = "GitLab",
                leadingIcon = {
                    Image(
                        src = "/gitlab-logo.png",
                        description = "GitLab Logo",
                        modifier = Modifier.maxSize(24.pt),
                    )
                }
            )
            LinkButton(
                modifier = Modifier.fillMaxWidth(),
                href = "https://bsky.app/profile/did:plc:acsscyhvxwflcggk7yoi6b3o",
                text = "Bluesky",
                leadingIcon = {
                    Image(
                        src = "/bluesky-logo.png",
                        description = "Bluesky Logo",
                        modifier = Modifier.maxSize(24.pt),
                    )
                }
            )
            LinkButton(
                modifier = Modifier.fillMaxWidth(),
                href = "mailto:hiya@niiranen.net",
                text = "Email",
                leadingIcon = {
                    ContactMailIcon(
                        modifier = Modifier
                            .align(Alignment.CenterVertically)
                            .color(ColorMode.current.toSitePalette().primary),
                    )
                }
            )
        }
    }
}
