package net.niiranen.site.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.RowScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.core.init.KobwebConfig
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.navigation.toOpenLinkStrategy
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.UncoloredLinkVariant
import com.varabyte.kobweb.silk.components.navigation.UndecoratedLinkVariant
import net.niiranen.site.ButtonVariant
import net.niiranen.site.components.text.LabelLarge
import org.jetbrains.compose.web.css.cssRem

@Composable
fun LinkButton(
    href: String,
    modifier: Modifier = Modifier,
    content: @Composable RowScope.() -> Unit,
) {
    val ctx = rememberPageContext()
    val onClick = { evt: SyntheticMouseEvent ->
        ctx.router.navigateTo(
            href,
            UpdateHistoryMode.PUSH,
            openInternalLinksStrategy = evt.toOpenLinkStrategy(KobwebConfig.Instance.openLinkStrategies.internal),
            openExternalLinksStrategy = evt.toOpenLinkStrategy(KobwebConfig.Instance.openLinkStrategies.external),
        )
        evt.preventDefault()
        evt.stopPropagation()
    }
    Button(
        onClick = onClick,
        modifier = modifier,
        variant = ButtonVariant,
    ) {
        LabelLarge {
            Link(
                path = href,
                variant = UndecoratedLinkVariant.then(UncoloredLinkVariant),
            ) {
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.spacedBy(1.cssRem),
                ) {
                    content()
                }
            }
        }
    }
}

@Composable
fun LinkButton(
    href: String,
    text: String,
    modifier: Modifier = Modifier,
    textAlign: TextAlign = TextAlign.Center,
    leadingIcon: @Composable RowScope.() -> Unit = {},
) {
    LinkButton(
        href = href,
        modifier = modifier,
    ) {
        leadingIcon()
        LabelLarge(text, modifier = Modifier.fillMaxWidth().textAlign(textAlign))
    }
}
