package net.niiranen.site.components.text

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.silk.style.toAttrs
import net.niiranen.site.HeadlineLargeStyle
import net.niiranen.site.HeadlineSmallStyle
import net.niiranen.site.HeadlineStyle
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Span
import org.w3c.dom.HTMLElement

@Composable
fun Headline(text: String, modifier: Modifier = Modifier) {
    Text(text, modifier, HeadlineStyle)
}

@Composable
fun HeadlineLarge(text: String, modifier: Modifier = Modifier) {
    Text(text, modifier, HeadlineLargeStyle)
}

@Composable
fun HeadlineSmall(text: String, modifier: Modifier = Modifier) {
    Text(text, modifier, HeadlineSmallStyle)
}

@Composable
fun Headline(
    attrs: AttrBuilderContext<HTMLElement>? = null,
    content: ContentBuilder<HTMLElement>? = null,
) = Span(attrs = HeadlineStyle.toAttrs(attrs), content = content)

@Composable
fun HeadlineLarge(
    attrs: AttrBuilderContext<HTMLElement>? = null,
    content: ContentBuilder<HTMLElement>? = null,
) = Span(attrs = HeadlineLargeStyle.toAttrs(attrs), content = content)

@Composable
fun HeadlineSmall(
    attrs: AttrBuilderContext<HTMLElement>? = null,
    content: ContentBuilder<HTMLElement>? = null,
) = Span(attrs = HeadlineSmallStyle.toAttrs(attrs), content = content)
