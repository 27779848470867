package net.niiranen.site.components.text

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.silk.style.toAttrs
import net.niiranen.site.BodyLargeStyle
import net.niiranen.site.BodySmallStyle
import net.niiranen.site.BodyStyle
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Span
import org.w3c.dom.HTMLElement

@Composable
fun Body(text: String, modifier: Modifier = Modifier) {
    Text(text, modifier, BodyStyle)
}

@Composable
fun BodyLarge(text: String, modifier: Modifier = Modifier) {
    Text(text, modifier, BodyLargeStyle)
}

@Composable
fun BodySmall(text: String, modifier: Modifier = Modifier) {
    Text(text, modifier, BodySmallStyle)
}

@Composable
fun Body(
    attrs: AttrBuilderContext<HTMLElement>? = null,
    content: ContentBuilder<HTMLElement>? = null,
) = Span(attrs = BodyStyle.toAttrs(attrs), content = content)


@Composable
fun BodyLarge(
    attrs: AttrBuilderContext<HTMLElement>? = null,
    content: ContentBuilder<HTMLElement>? = null,
) = Span(attrs = BodyLargeStyle.toAttrs(attrs), content = content)

@Composable
fun BodySmall(
    attrs: AttrBuilderContext<HTMLElement>? = null,
    content: ContentBuilder<HTMLElement>? = null,
) = Span(attrs = BodySmallStyle.toAttrs(attrs), content = content)
