package net.niiranen.site

import androidx.compose.runtime.Immutable
import com.varabyte.kobweb.compose.css.FontOpticalSizing
import com.varabyte.kobweb.compose.css.FontStyle
import com.varabyte.kobweb.compose.css.FontVariationSettings
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextTransform
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.display
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontOpticalSizing
import com.varabyte.kobweb.compose.ui.modifiers.fontStyle
import com.varabyte.kobweb.compose.ui.modifiers.fontVariationSettings
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.textTransform
import com.varabyte.kobweb.compose.ui.modifiers.whiteSpace
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.style.CssStyleBaseScope
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.palette.background
import com.varabyte.kobweb.silk.theme.colors.palette.border
import com.varabyte.kobweb.silk.theme.colors.palette.color
import com.varabyte.kobweb.silk.theme.colors.palette.link
import org.jetbrains.compose.web.css.DisplayStyle

private val primaryLight = Color.argb(0xFF31628D)
private val onPrimaryLight = Color.argb(0xFFFFFFFF)
private val primaryContainerLight = Color.argb(0xFFCFE5FF)
private val onPrimaryContainerLight = Color.argb(0xFF001D33)
private val secondaryLight = Color.argb(0xFF526070)
private val onSecondaryLight = Color.argb(0xFFFFFFFF)
private val secondaryContainerLight = Color.argb(0xFFD5E4F7)
private val onSecondaryContainerLight = Color.argb(0xFF0E1D2A)
private val tertiaryLight = Color.argb(0xFF695779)
private val onTertiaryLight = Color.argb(0xFFFFFFFF)
private val tertiaryContainerLight = Color.argb(0xFFEFDBFF)
private val onTertiaryContainerLight = Color.argb(0xFF231533)
private val errorLight = Color.argb(0xFFBA1A1A)
private val onErrorLight = Color.argb(0xFFFFFFFF)
private val errorContainerLight = Color.argb(0xFFFFDAD6)
private val onErrorContainerLight = Color.argb(0xFF410002)
private val backgroundLight = Color.argb(0xFFF7F9FF)
private val onBackgroundLight = Color.argb(0xFF181C20)
private val surfaceLight = Color.argb(0xFFF7F9FF)
private val onSurfaceLight = Color.argb(0xFF181C20)
private val surfaceVariantLight = Color.argb(0xFFDEE3EB)
private val onSurfaceVariantLight = Color.argb(0xFF42474E)
private val outlineLight = Color.argb(0xFF72777F)
private val outlineVariantLight = Color.argb(0xFFC2C7CF)
private val scrimLight = Color.argb(0xFF000000)
private val inverseSurfaceLight = Color.argb(0xFF2D3135)
private val inverseOnSurfaceLight = Color.argb(0xFFEFF1F6)
private val inversePrimaryLight = Color.argb(0xFF9CCBFB)
private val surfaceDimLight = Color.argb(0xFFD8DAE0)
private val surfaceBrightLight = Color.argb(0xFFF7F9FF)
private val surfaceContainerLowestLight = Color.argb(0xFFFFFFFF)
private val surfaceContainerLowLight = Color.argb(0xFFF2F3F9)
private val surfaceContainerLight = Color.argb(0xFFECEEF4)
private val surfaceContainerHighLight = Color.argb(0xFFE6E8EE)
private val surfaceContainerHighestLight = Color.argb(0xFFE0E2E8)

private val primaryDark = Color.argb(0xFF9CCBFB)
private val onPrimaryDark = Color.argb(0xFF003354)
private val primaryContainerDark = Color.argb(0xFF114A73)
private val onPrimaryContainerDark = Color.argb(0xFFCFE5FF)
private val secondaryDark = Color.argb(0xFFB9C8DA)
private val onSecondaryDark = Color.argb(0xFF243240)
private val secondaryContainerDark = Color.argb(0xFF3A4857)
private val onSecondaryContainerDark = Color.argb(0xFFD5E4F7)
private val tertiaryDark = Color.argb(0xFFD4BEE6)
private val onTertiaryDark = Color.argb(0xFF392A49)
private val tertiaryContainerDark = Color.argb(0xFF504061)
private val onTertiaryContainerDark = Color.argb(0xFFEFDBFF)
private val errorDark = Color.argb(0xFFFFB4AB)
private val onErrorDark = Color.argb(0xFF690005)
private val errorContainerDark = Color.argb(0xFF93000A)
private val onErrorContainerDark = Color.argb(0xFFFFDAD6)
private val backgroundDark = Color.argb(0xFF101418)
private val onBackgroundDark = Color.argb(0xFFE0E2E8)
private val surfaceDark = Color.argb(0xFF101418)
private val onSurfaceDark = Color.argb(0xFFE0E2E8)
private val surfaceVariantDark = Color.argb(0xFF42474E)
private val onSurfaceVariantDark = Color.argb(0xFFC2C7CF)
private val outlineDark = Color.argb(0xFF8C9199)
private val outlineVariantDark = Color.argb(0xFF42474E)
private val scrimDark = Color.argb(0xFF000000)
private val inverseSurfaceDark = Color.argb(0xFFE0E2E8)
private val inverseOnSurfaceDark = Color.argb(0xFF2D3135)
private val inversePrimaryDark = Color.argb(0xFF31628D)
private val surfaceDimDark = Color.argb(0xFF101418)
private val surfaceBrightDark = Color.argb(0xFF36393E)
private val surfaceContainerLowestDark = Color.argb(0xFF0B0E12)
private val surfaceContainerLowDark = Color.argb(0xFF181C20)
private val surfaceContainerDark = Color.argb(0xFF1C2024)
private val surfaceContainerHighDark = Color.argb(0xFF272A2F)
private val surfaceContainerHighestDark = Color.argb(0xFF32353A)

@Immutable
data class ColorScheme(
    val primary: Color,
    val onPrimary: Color,
    val primaryContainer: Color,
    val onPrimaryContainer: Color,
    val secondary: Color,
    val onSecondary: Color,
    val secondaryContainer: Color,
    val onSecondaryContainer: Color,
    val tertiary: Color,
    val onTertiary: Color,
    val tertiaryContainer: Color,
    val onTertiaryContainer: Color,

    val error: Color,
    val onError: Color,
    val errorContainer: Color,
    val onErrorContainer: Color,

    val background: Color,
    val onBackground: Color,

    val surfaceDim: Color,
    val surface: Color,
    val onSurface: Color,
    val surfaceVariant: Color,
    val onSurfaceVariant: Color,

    val outline: Color,
    val outlineVariant: Color,
    val scrim: Color,

    val inverseSurface: Color,
    val inverseOnSurface: Color,
    val inversePrimary: Color,
    val surfaceBright: Color,
    val surfaceContainerLowest: Color,
    val surfaceContainerLow: Color,
    val surfaceContainer: Color,
    val surfaceContainerHigh: Color,
    val surfaceContainerHighest: Color,
)

object SitePalettes {
    val light = ColorScheme(
        primary = primaryLight,
        onPrimary = onPrimaryLight,
        primaryContainer = primaryContainerLight,
        onPrimaryContainer = onPrimaryContainerLight,
        secondary = secondaryLight,
        onSecondary = onSecondaryLight,
        secondaryContainer = secondaryContainerLight,
        onSecondaryContainer = onSecondaryContainerLight,
        tertiary = tertiaryLight,
        onTertiary = onTertiaryLight,
        tertiaryContainer = tertiaryContainerLight,
        onTertiaryContainer = onTertiaryContainerLight,
        error = errorLight,
        onError = onErrorLight,
        errorContainer = errorContainerLight,
        onErrorContainer = onErrorContainerLight,
        background = backgroundLight,
        onBackground = onBackgroundLight,
        surface = surfaceLight,
        onSurface = onSurfaceLight,
        surfaceVariant = surfaceVariantLight,
        onSurfaceVariant = onSurfaceVariantLight,
        outline = outlineLight,
        outlineVariant = outlineVariantLight,
        scrim = scrimLight,
        inverseSurface = inverseSurfaceLight,
        inverseOnSurface = inverseOnSurfaceLight,
        inversePrimary = inversePrimaryLight,
        surfaceDim = surfaceDimLight,
        surfaceBright = surfaceBrightLight,
        surfaceContainerLowest = surfaceContainerLowestLight,
        surfaceContainerLow = surfaceContainerLowLight,
        surfaceContainer = surfaceContainerLight,
        surfaceContainerHigh = surfaceContainerHighLight,
        surfaceContainerHighest = surfaceContainerHighestLight,
    )
    val dark = ColorScheme(
        primary = primaryDark,
        onPrimary = onPrimaryDark,
        primaryContainer = primaryContainerDark,
        onPrimaryContainer = onPrimaryContainerDark,
        secondary = secondaryDark,
        onSecondary = onSecondaryDark,
        secondaryContainer = secondaryContainerDark,
        onSecondaryContainer = onSecondaryContainerDark,
        tertiary = tertiaryDark,
        onTertiary = onTertiaryDark,
        tertiaryContainer = tertiaryContainerDark,
        onTertiaryContainer = onTertiaryContainerDark,
        error = errorDark,
        onError = onErrorDark,
        errorContainer = errorContainerDark,
        onErrorContainer = onErrorContainerDark,
        background = backgroundDark,
        onBackground = onBackgroundDark,
        surface = surfaceDark,
        onSurface = onSurfaceDark,
        surfaceVariant = surfaceVariantDark,
        onSurfaceVariant = onSurfaceVariantDark,
        outline = outlineDark,
        outlineVariant = outlineVariantDark,
        scrim = scrimDark,
        inverseSurface = inverseSurfaceDark,
        inverseOnSurface = inverseOnSurfaceDark,
        inversePrimary = inversePrimaryDark,
        surfaceDim = surfaceDimDark,
        surfaceBright = surfaceBrightDark,
        surfaceContainerLowest = surfaceContainerLowestDark,
        surfaceContainerLow = surfaceContainerLowDark,
        surfaceContainer = surfaceContainerDark,
        surfaceContainerHigh = surfaceContainerHighDark,
        surfaceContainerHighest = surfaceContainerHighestDark,
    )
}

@Immutable
data class ColorFamily(
    val color: Color,
    val onColor: Color,
    val colorContainer: Color,
    val onColorContainer: Color,
)

fun ColorMode.toSitePalette(): ColorScheme {
    return when (this) {
        ColorMode.LIGHT -> SitePalettes.light
        ColorMode.DARK -> SitePalettes.dark
    }
}

fun ColorMode.toColorFamily(): ColorFamily {
    return when (this) {
        ColorMode.LIGHT -> TODO()
        ColorMode.DARK -> TODO()
    }
}

fun ColorScheme.toPrimaryColorFamily(): ColorFamily = ColorFamily(
    color = primary,
    onColor = onPrimary,
    colorContainer = primaryContainer,
    onColorContainer = onPrimaryContainer,
)

fun ColorScheme.toSecondaryColorFamily(): ColorFamily = ColorFamily(
    color = secondary,
    onColor = onSecondary,
    colorContainer = secondaryContainer,
    onColorContainer = onSecondaryContainer,
)

fun ColorScheme.toTertiaryColorFamily(): ColorFamily = ColorFamily(
    color = tertiary,
    onColor = onTertiary,
    colorContainer = tertiaryContainer,
    onColorContainer = onTertiaryContainer,
)

fun CssStyleBaseScope.primaryColorFamily() = colorMode.toSitePalette().toPrimaryColorFamily()
fun CssStyleBaseScope.secondaryColorFamily() = colorMode.toSitePalette().toSecondaryColorFamily()
fun CssStyleBaseScope.tertiaryColorFamily() = colorMode.toSitePalette().toTertiaryColorFamily()

private val BaseFont = Modifier
    .fontStyle(FontStyle.Normal)

val RobotoFlex = BaseFont.fontFamily("Roboto Flex", "Noto Color Emoji", "sans-serif")
    .fontOpticalSizing(FontOpticalSizing.Auto)
    .fontVariationSettings(
        FontVariationSettings.Axis("slnt", 0),
        FontVariationSettings.Axis("wdth", 100),
        FontVariationSettings.Axis("GRAD", 0),
        FontVariationSettings.Axis("XOPQ", 96),
        FontVariationSettings.Axis("XTRA", 468),
        FontVariationSettings.Axis("YOPQ", 79),
        FontVariationSettings.Axis("YTAS", 750),
        FontVariationSettings.Axis("YTDE", -203),
        FontVariationSettings.Axis("YTFI", 738),
        FontVariationSettings.Axis("YTLC", 514),
        FontVariationSettings.Axis("YTUC", 712),
    )

val RobotoSlab = BaseFont.fontFamily("Roboto Slab", "Noto Color Emoji", "serif")
    .fontOpticalSizing(FontOpticalSizing.Auto)

val NotoSerif = BaseFont.fontFamily("Noto Serif", "Noto Color Emoji", "serif")
    .fontOpticalSizing(FontOpticalSizing.Auto)
    .fontVariationSettings(
        FontVariationSettings.Axis("wdth", 100),
    )

val NotoSans = BaseFont.fontFamily("Noto Sans", "Noto Color Emoji", "sans-serif")
    .fontOpticalSizing(FontOpticalSizing.Auto)
    .fontVariationSettings(
        FontVariationSettings.Axis("wdth", 100),
    )

val FiraCode = BaseFont.fontFamily("Fira Code", "Noto Color Emoji", "monospace")
    .fontOpticalSizing(FontOpticalSizing.Auto)

val Symbols = BaseFont.fontFamily("Material Symbols Rounded")
    .fontWeight(FontWeight.Normal)
    .display(DisplayStyle.InlineBlock)
    .lineHeight(1)
    .textTransform(TextTransform.None)
    .whiteSpace(WhiteSpace.NoWrap)
    .fontVariationSettings(
        FontVariationSettings.Axis("FILL", 0),
        FontVariationSettings.Axis("GRAD", 0),
    )


@InitSilk
fun initTheme(ctx: InitSilkContext) {
    ctx.theme.palettes.light.background = SitePalettes.light.background
    ctx.theme.palettes.light.color = SitePalettes.light.onBackground
    ctx.theme.palettes.light.border = SitePalettes.light.outline
    ctx.theme.palettes.light.link.default = SitePalettes.light.primary
    ctx.theme.palettes.light.link.visited = SitePalettes.light.tertiary
    ctx.theme.palettes.dark.background = SitePalettes.dark.background
    ctx.theme.palettes.dark.color = SitePalettes.dark.onBackground
    ctx.theme.palettes.dark.border = SitePalettes.dark.outline
    ctx.theme.palettes.dark.link.default = SitePalettes.dark.primary
    ctx.theme.palettes.dark.link.visited = SitePalettes.dark.tertiary
}
