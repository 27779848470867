package net.niiranen.site.components.text

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.silk.style.toAttrs
import net.niiranen.site.TitleLargeStyle
import net.niiranen.site.TitleSmallStyle
import net.niiranen.site.TitleStyle
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Span
import org.w3c.dom.HTMLElement

@Composable
fun Title(text: String, modifier: Modifier = Modifier) {
    Text(text, modifier, TitleStyle)
}

@Composable
fun TitleLarge(text: String, modifier: Modifier = Modifier) {
    Text(text, modifier, TitleLargeStyle)
}

@Composable
fun TitleSmall(text: String, modifier: Modifier = Modifier) {
    Text(text, modifier, TitleSmallStyle)
}

@Composable
fun Title(
    attrs: AttrBuilderContext<HTMLElement>? = null,
    content: ContentBuilder<HTMLElement>? = null,
) = Span(attrs = TitleStyle.toAttrs(attrs), content = content)

@Composable
fun TitleLarge(
    attrs: AttrBuilderContext<HTMLElement>? = null,
    content: ContentBuilder<HTMLElement>? = null,
) = Span(attrs = TitleLargeStyle.toAttrs(attrs), content = content)

@Composable
fun TitleSmall(
    attrs: AttrBuilderContext<HTMLElement>? = null,
    content: ContentBuilder<HTMLElement>? = null,
) = Span(attrs = TitleSmallStyle.toAttrs(attrs), content = content)
