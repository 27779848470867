package net.niiranen.site.components.text

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.whiteSpace
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.GeneralKind
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text as JbText

@Composable
fun Text(text: String, modifier: Modifier = Modifier) {
    Span(
        attrs = modifier
            .thenIf(text.startsWith(' ') || text.endsWith(' ')) {
                Modifier.whiteSpace(WhiteSpace.PreWrap)
            }
            .toAttrs()
    ) {
        JbText(text)
    }
}

@Composable
fun Text(text: String, modifier: Modifier = Modifier, style: CssStyle<GeneralKind>) {
    Span(
        attrs = style
            .toModifier()
            .then(modifier)
            .thenIf(text.startsWith(' ') || text.endsWith(' ')) {
                Modifier.whiteSpace(WhiteSpace.PreWrap)
            }
            .toAttrs()
    ) {
        JbText(text)
    }
}
