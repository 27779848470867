package net.niiranen.site.components.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.setVariable
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.whiteSpace
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.UncoloredLinkVariant
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.style.vars.color.ColorVar
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import net.niiranen.site.components.text.Label
import net.niiranen.site.toSitePalette
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Footer as JbFooter

val FooterStyle = CssStyle.base {
    Modifier
        .backgroundColor(colorMode.toSitePalette().surfaceVariant)
        .color(colorMode.toSitePalette().onSurfaceVariant)
        .padding(topBottom = 0.3.cssRem, leftRight = 10.percent)
}

@Composable
fun Footer(modifier: Modifier = Modifier) {
    Box(FooterStyle.toModifier().then(modifier), contentAlignment = Alignment.Center) {
        JbFooter(
            Modifier.textAlign(TextAlign.Center).toAttrs()
        ) {
            Label {
                val sitePalette = ColorMode.current.toSitePalette()
                Text("Built with ")
                Link(
                    "https://github.com/varabyte/kobweb",
                    "Kobweb",
                    Modifier.setVariable(ColorVar, sitePalette.primary),
                    variant = UncoloredLinkVariant
                )
                Text(", template designed by ")

                // Huge thanks to UI Rocket (https://ui-rocket.com) for putting this great template design together for us!
                // If you like what you see here and want help building your own site, consider checking out their services.
                Link(
                    "https://ui-rocket.com",
                    "UI Rocket",
                    Modifier.setVariable(ColorVar, sitePalette.primary).whiteSpace(WhiteSpace.NoWrap),
                    variant = UncoloredLinkVariant
                )
            }
        }
    }
}
