package net.niiranen.site

import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.alignItems
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.display
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.setVariable
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.silk.components.forms.ButtonStyle
import com.varabyte.kobweb.silk.components.forms.ButtonVars
import com.varabyte.kobweb.silk.components.layout.HorizontalDividerStyle
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.addVariantBase
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.style.extendedByBase
import com.varabyte.kobweb.silk.style.vars.color.BackgroundColorVar
import com.varabyte.kobweb.silk.style.vars.color.ColorVar
import com.varabyte.kobweb.silk.theme.modifyStyleBase
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@InitSilk
fun initSiteStyles(ctx: InitSilkContext) {
    // Silk dividers only extend 90% by default; we want full width dividers in our site
    ctx.theme.modifyStyleBase(HorizontalDividerStyle) {
        Modifier.fillMaxWidth()
    }
}

val CircleButtonVariant = ButtonStyle.addVariantBase {
    Modifier.padding(0.px).borderRadius(50.percent)
}

val ButtonVariant = ButtonStyle.addVariantBase {
    val colors = primaryColorFamily()
    Modifier
        .setVariable(ButtonVars.BackgroundDefaultColor, colors.colorContainer)
        .setVariable(ButtonVars.BackgroundPressedColor, colors.color)
        .setVariable(ButtonVars.Color, colors.color)
}

val UncoloredButtonVariant = ButtonStyle.addVariantBase {
    Modifier.setVariable(ButtonVars.BackgroundDefaultColor, Colors.Transparent)
}

val ContainerStyle = CssStyle.base {
    val colors = primaryColorFamily()
    Modifier
        .display(DisplayStyle.Flex)
        .textAlign(TextAlign.Left)
        .alignItems(AlignItems.Center)
        .backgroundColor(colors.colorContainer)
        .color(colors.onColorContainer)
        .setVariable(ColorVar, colors.color)
        .setVariable(BackgroundColorVar, colors.colorContainer)
}

val CardStyle = ContainerStyle.extendedByBase {
    val colors = secondaryColorFamily()
    Modifier
        .clip(shape = Rect(cornerRadius = 10.px))
        .backgroundColor(colors.colorContainer)
        .color(colors.onColorContainer)
        .setVariable(ColorVar, colors.color)
        .setVariable(BackgroundColorVar, colors.colorContainer)
}
