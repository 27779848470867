package net.niiranen.site.components.text

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.silk.style.toAttrs
import net.niiranen.site.QuoteStyle
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Blockquote
import org.jetbrains.compose.web.dom.ContentBuilder
import org.w3c.dom.HTMLElement


@Composable
fun Quote(text: String, modifier: Modifier = Modifier) {
    Blockquote {
        Text(text, modifier, QuoteStyle)
    }
}

@Composable
fun Quote(
    attrs: AttrBuilderContext<HTMLElement>? = null,
    content: ContentBuilder<HTMLElement>? = null,
) = Blockquote(QuoteStyle.toAttrs(attrs), content)